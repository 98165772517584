
import {useState, useMemo, useEffect } from "react";

import CodeEditor from "../../components/code/CodeEditor";
import ProblemDescription from "../../components/code/ProblemDescription"; 
import ResizableTwoPanel from "../../components/general/ResizableTwoPanel";


export default function CodeActivity({title, description, instructions, codeSnippets, initEditorValue, answers, activityCompletedCallback, hasCompleted})
{
    const [ editorValue, setEditorValue ] = useState(initEditorValue);
    const [ instructionsCompleted, setInstructionsCompleted ] = useState(null);
 

    useEffect(() => {
        // Fill in correctly based on if the activity has already been completed or not
        setInstructionsCompleted(new Array(instructions.length).fill(hasCompleted));

        setEditorValue(initEditorValue);
    }, [instructions]);

    const instructIdx = useMemo(() => {
        // Figure out the current instruction index based on the first false 
        if (instructionsCompleted)
        {
            const updatedInstructIdx = instructionsCompleted.findIndex(item => !item);
            return updatedInstructIdx;
        } else {
            return -1;
        }
         
    }, [instructionsCompleted, title]);


    // TODO: THIS NEEDS TO BE MOVED TO A CLOUD FUNCTION
    /* Validate the output of the Python code */
    function validateOutput(terminalOutput, pythonGlobals) {
        console.log("pythonGlobals ", pythonGlobals)
        const curInstructionsCompleted = [...instructionsCompleted];

        // Split the terminal output into lines
        const outputLines = terminalOutput.split(/\r?\n|\r|\n/g);
        if (outputLines.length === 0) {
            return;
        }
    
        // Remove the last line that contains the metadata about the program
        const localsLine = outputLines.pop().split("<ENDOFCODE/> ").pop();
        if (!localsLine) {
            return;
        }
    
        const localsPart = localsLine.replaceAll("}", "").split("_pyodide_core': <module '_pyodide_core' (built-in)>")[1];
        // if (!localsPart) {
        //     return;
        // }
    
        const locals = localsPart.split(", ");
        const localVars = {};
    
        // Iterate over the variables from locals (that were created by the user)
        for (let i = 1; i < locals.length; i++) {
            // Add the variable, function, class, etc.
            const varDictSplit = locals[i].replaceAll("'", "").split(":");
            if (varDictSplit.length === 2) {
                localVars[varDictSplit[0].trim()] = varDictSplit[1].trim();
            }
        }
        console.log("localVars: ", localVars);
        terminalOutput = outputLines.join("\r\n");
        let curIntructIdx = instructIdx;
        let prevIntructIdx = -1;
    
        while (curIntructIdx !== -1 && curIntructIdx < answers.length && prevIntructIdx !== curIntructIdx) {
            prevIntructIdx = curIntructIdx;   
            let correctAnswers = answers[curIntructIdx];
    
            if (correctAnswers.hasOwnProperty("alternate")) {
                // The current instruction is a print statement that is outputted to the terminal
                // So, check if it is one of the accepted answers 
                correctAnswers = correctAnswers.alternate;
                if (typeof correctAnswers === "string") {
                    correctAnswers = [correctAnswers];
                }
                let shouldBreakOuter = false;
                for (let i = 0; i < correctAnswers.length; i++) {
                    const correctAnswer = formatCorrectAnswer(correctAnswers[i]);

                    if (terminalOutput.trim() === correctAnswer) {
                        curInstructionsCompleted[curIntructIdx] = true;
                        curIntructIdx += 1;
                        break;
                    }

                    for (let j = 0; j < outputLines.length; j++) {
                        if (outputLines[j].trim() === correctAnswer) {
                            curInstructionsCompleted[curIntructIdx] = true;
                            curIntructIdx += 1;
                            shouldBreakOuter = true;
                            break;    
                        }
                    }
                    if (shouldBreakOuter) {
                        break;
                    }
                }
            } else {
                // The current instruction requires variable(s) to be defined, so verify that are all defined correctly
                let correctVariables = correctAnswers.variable;
                let hasAllVars = true;
                console.log(localVars);
                for (const propertyVar in correctVariables) {
                    // Verify the variable is defined in the globals correctly
                    var definedInGlobals = pythonGlobals.hasOwnProperty(propertyVar) && pythonGlobals[propertyVar] === correctVariables[propertyVar];

                    // In the future, this should be removed as we get the globals directly so parsing is not needed
                    var definedLocally = localVars.hasOwnProperty(propertyVar) && localVars[propertyVar] === correctVariables[propertyVar];
                    console.log("definedInGlobals: ", definedInGlobals, " definedLocally: ", definedLocally);
                    if (!definedLocally && !definedInGlobals) {
                        hasAllVars = false;
                        break;
                    }
                }
                if (hasAllVars) {
                    curInstructionsCompleted[curIntructIdx] = true;
                    curIntructIdx += 1;
                }
            }
        }

        setInstructionsCompleted(curInstructionsCompleted);

        if (curIntructIdx >= instructionsCompleted.length) {
            activityCompletedCallback(editorValue);
        }
    }

    function formatCorrectAnswer(correctAnswer)
    {
        let formattedAnswer = correctAnswer;
        
        // Replace the macro with the user's name
        if (correctAnswer.includes("<FIRST_NAME>")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            // Replace with first name
            formattedAnswer = formattedAnswer.replace(
                "<FIRST_NAME>",
                userData.userData.first_name
            );
        }

        return formattedAnswer;
    }

    return (
        <div className="python-activity-panels">
            {instructionsCompleted && 
            <ResizableTwoPanel 
                PanelOne={<ProblemDescription
                    title={title}
                    description={description}
                    instructions={instructions}
                    codeSnippets={codeSnippets}
                    instructionsCompleted={instructionsCompleted}
                    />}
                PanelTwo={<CodeEditor
                    editorContent={editorValue}
                    setEditorContent={(editorValue) => {
                        setEditorValue(editorValue);                            
                    }}
                    setCodeOutput={(output, pythonGlobals) => {
                        validateOutput(output, pythonGlobals);
                        // NEED TO PERFORM A FIREBASE FUNCTION HERE TO VALIDATE OUTPUT
                    }}
                />}/>
            }
        </div>
    )
}